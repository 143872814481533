import React from "react";
import LogoCC from "../../assets/img/logos/CC/MainCCLogo.png";
import LogoE2e from "../../assets/img/logos/e2e/MainE2eLogo.png";
import LogoPortuarios from "../../assets/img/logos/Portuarios/MainPortuariosLogo.png";
import LogoEglobe from "../../assets/img/logos/Eglobe/MainEglobeLogo.png";
import LogoMegaship from "../../assets/img/logos/Megaship/MainMegashipLogo.png";
import LogoMegaonline from "../../assets/img/logos/Megaonline/MainMegaonlineLogo.png";
import LogoPlanet from "../../assets/img/logos/PlanetAirCargo/MainPlanetLogo.png";
import LogoIfreight from "../../assets/img/logos/ifreight/MainIfreightLogo.png";
import LogoErship from "../../assets/img/logos/Ership/MainErshipLogo.png";

function renderSwitch() {
    switch(process.env.REACT_APP_CUSTOMER) {
        case 'CC':
            return <img src={LogoCC} alt="Logo"/>;
        case 'E2e':
            return <img src={LogoE2e} alt="Logo"/>;
        case 'Portuarios':
            return <img src={LogoPortuarios} alt="Logo"/>;
        case 'Eglobe':
            return <img src={LogoEglobe} alt="Logo"/>;
        case 'Megaship':
            return <img src={LogoMegaship} alt="Logo"/>;
        case 'Megaonline':
            return <img src={LogoMegaonline} alt="Logo"/>;
        case 'Planet':
            return <img src={LogoPlanet} alt="Logo"/>;
        case 'iFreight':
            return <img src={LogoIfreight} alt="Logo"/>;
        case 'Ership':
            return <img src={LogoErship} alt="Logo"/>;
        default:
            return 'foo';
    }
}

function MainLogo() {
    return renderSwitch();
}

export default MainLogo;
